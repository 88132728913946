<template>
    <div id="app">
        <header>
            <div class="logo">
                <router-link to="/">
                    <img
                        srcset="/assets/logo/logo.png 1x,
                /assets/logo/logo@2x.png 2x,
                /assets/logo/logo@3x.png 3x"
                        src="/assets/logo/logo.png"
                        alt="Logo du Moulin Hubeau">
                </router-link>
            </div>
            <nav>
                <router-link to="/">Accueil</router-link>
                <router-link to="horaire-et-acces">Horaires & Accès</router-link>
                <a class="facebook" target="_blank" rel="noopener noreferrer" href="http://m.me/MoulinHubeau">Contactez-nous
                    sur Facebook</a>
                <div @click="toggleMenu" v-bind:class="isMenuOpen ? 'menu open' : 'menu'">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                </div>
            </nav>
        </header>
        <router-view id="content"></router-view>
        <footer>
            <img alt="logo du Moulin Hubeau en negatif" src="assets/MoulinHubeauNext.png" class="logo">
            <div class="info adresse">
                <h3>Adresse</h3>
                <p>
                    Pêche à la truite du Moulin Hubeau<br>SARL Le Moulin Hubeau<br>49150 Baugé-en-Anjou
                </p>
            </div>
            <div class="info telephone">
                <h3>Téléphone</h3>
                <p>
                    <a href="tel:+33241890628">02 41 89 06 28</a>
                </p>
            </div>
            <div class="info formule">
                <h3>Nos Formules</h3>
                <ul>
                    <li v-for="offer in offers"><a :href="`/#${offer.slug}`">{{offer.name}}</a></li>
                </ul>
            </div>
            <div class="info utile">
                <h3>Informations utiles</h3>
                <ul>
                    <li><a href="/horaire-et-acces">Horaires & accès</a></li>
                </ul>
            </div>
            <div class="info link">
                <h3>Liens utiles</h3>
                <ul>
                    <li>
                        <a href="http://marche-fermier.com/">Marché fermier de St-Sylvain d'Anjou</a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/osaveursbaugeoises/">Ô saveur baugeoise</a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/stevenmarion.allard">Ô Coin des Baugeois</a>
                    </li>
                </ul>
            </div>
        </footer>
    </div>
</template>

<script>
    import ImageCover from './components/ImageCover'
    import Helpline from './components/Helpline'

    export default {
        name: 'app',
        components: {Helpline, ImageCover}, created() {
            (async () => {
                this.offerStore = (await import("/store/formules.js")).OfferStore;
                this.offers = this.offerStore.offers;
            })();
        },
        data () {
            return {
                offers: [],
                offerStore: [],
                isMenuOpen: false,
            }
        },
        methods: {
            toggleMenu () {
                this.$data.isMenuOpen = !this.$data.isMenuOpen
            }
        }
    }
</script>

<style lang="scss">
    @import "global-style/_variables.scss";
    @import "global-style/_pageStructures.scss";

    @font-face {
        font-family: "Source Serif";
        src: local('Time New Roman'), url(assets/fonts/SourceSerifVariable-Roman.ttf.woff2) format("truetype-variations");
        font-display: swap;
    }

    @font-face {
        font-family: "Source Serif Italic";
        src: local('Time New Roman'), url(assets/fonts/SourceSerifVariable-Italic.ttf.woff2) format("truetype-variations");
        font-display: swap;
    }

    @font-face {
        font-family: Inter;
        src: local('sans-serif'), url(assets/fonts/Inter.var.woff2) format("woff2"),
        url(assets/fonts/Inter.var.ttf) format("ttf");
        font-display: swap;
    }

    html {
        width: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    body {
        margin: 0;
        background: #f9f9fa;
        font-family: Inter, sans-serif;
        //user-select: none;
        width: 100vw;
        -webkit-overflow-scrolling: touch;

    }

    #app {
        width: 100%;
        display: grid;
        grid-template: "header" auto "exergue" auto "frontline" auto "content" auto "footer" auto
            / 1fr;
    }

    header {
        grid-area: header;
        display: grid;
        grid-template: "logo menu" 1fr / 300px 1fr;
        grid-gap: 20px;
        margin-left: 15%;
        margin-right: 15%;
        padding-top: 40px;
        padding-bottom: 40px;
        @media screen and (max-width: $first-brake) {
            grid-template: "logo menu ." 40px
                / 250px 1fr;
            margin-left: 20px;
            margin-right: 20px;
        }
        @media screen and (max-width: $second-brake) {
            grid-template:
                "menu" 64px
                "logo" auto
                 / 1fr;
            margin-left: 10px;
            margin-right: 10px;
            padding-top: 10px;
            padding-bottom: 40px;
        }

        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            grid-area: logo;
            box-sizing: content-box;

            img {
                width: 100%;
                max-width: 350px;

            }

            @media screen and (max-width: $second-brake) {
                padding-top: 24px;
                height: fit-content;
                img {
                    width: 100%;
                }
            }
        }

        nav {
            grid-area: menu;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            a {
                margin: 0 10px;
                font-size: 1.2em;
                font-weight: 300;
                outline: none;
                padding: 3px 0;
                transition: border .2s;
                text-decoration: none;
                color: black;
                border-bottom: 3px solid transparent;

                &:hover {
                    border-bottom: 3px solid grey;
                }

                &.router-link-exact-active {
                    border-bottom: 3px solid #1a68ff;
                }

                &.facebook {
                    font-size: 0.9em;
                    padding: 10px 10px 10px 32px;
                    color: white;
                    border: 0;
                    border-radius: 50px;
                    background: $blue url(assets/messenger.png) left 10px center no-repeat;
                    background-size: 15px;
                    transition: background 0.2s;
                    margin-right: 0;

                    &:hover {
                        background: darken($blue, 10%) url(assets/messenger.png) left 10px center no-repeat;
                        background-size: 15px;
                    }
                }
            }

            .menu {
                display: none;
                width: 40px;
                height: 40px;
                transition: 0.4s;
                padding-left: 5px;
                padding-top: 6px;

                div {
                    width: 30px;
                    height: 2px;
                    background-color: black;
                    margin: 7px 0;
                    transition: 0.4s;
                    transform-origin: left center;
                    transform-origin: center;
                }

                &.open {
                    transform: rotateZ(180deg);

                    .bar1 {
                        transform: translateY(9px) rotateZ(45deg);
                    }

                    .bar2 {
                        opacity: 0;
                    }

                    .bar3 {
                        transform: translateY(-9px) rotateZ(-45deg);
                    }
                }
            }

            @media screen and (max-width: $second-brake) {
                background: white;
                justify-content: center;
                z-index: 100000;
                border-bottom: 1px solid #d0d0d0;
                height: 64px;
                box-sizing: border-box;
                align-items: stretch;
                position: -webkit-sticky;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                a {
                    font-size: 14px;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                }
                .facebook {
                    display: none;
                }
            }
        }
    }

    .frontline {
        grid-area: frontline;
    }

    main {
        margin-left: 15%;
        margin-right: 15%;
        padding-bottom: 40px;

        @media screen and (max-width: $first-brake) {
            margin-left: 20px;
            margin-right: 20px;
        }
        @media screen and (max-width: $second-brake) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    footer {
        grid-area: footer;
        background: #000;
        color: #fff;
        padding: 40px;
        display: grid;
        grid-template: "a a a a" auto "b d e f" 1fr "c d e f" 1fr;
        grid-gap: 20px;
        @media screen and (max-width: $first-brake) {
            grid-template: "a a" auto "b e" auto "c e" auto "d f" auto;
        }
        @media screen and (max-width: $second-brake) {
            grid-template: "a" auto "b" auto "c" auto "d" auto "e" auto "f" auto;
        }


        .logo {
            width: 200px;
            margin-bottom: 20px;
            grid-area: a;
        }

        .adresse {
            grid-area: b;
        }

        .telephone {
            grid-area: c;
        }

        .formule {
            grid-area: d;
        }

        .utile {

            grid-area: e;
        }

        .link {
            grid-area: f;
        }

        & > img {
            grid-area: a;
        }

        .info {
            h3 {
                color: #919496;
                font-size: 12pt;
                font-variant: all-small-caps;
                margin: 10px 0;
            }

            p {
                font-size: 12pt;
                font-weight: 300;
                list-style: none;
                margin: 0;
            }

            a {
                color: #fff;
                height: 20px;
            }

            ul {
                margin: 0;
                padding: 0;

                li {
                    font-size: 12pt;
                    font-weight: 300;
                    list-style: none;
                    margin: 0 0 5px;
                }
            }
        }
    }
</style>
